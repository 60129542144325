<template>
  <div>
    <h3 class="text-base mt-4">Address</h3>

    <div
      class="flex flex-col md:flex-row gap-4 mt-4 md:gap-8 md:pl-4 md:border-l border-gray-200"
    >
      <div>
        <label>Address</label>
        <p>
          {{ address1
          }}{{ address2 ? ` ${address2}` : "" }},
          {{ city }}, {{ state }}
          {{ zip }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "SectionAddressView",
    props: ['address1', 'address2', 'city', 'state', 'zip']
}
</script>