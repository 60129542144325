<template>
  <div class="container mx-auto" style="max-width: 960px">
    <SectionAlreadyRegistered
      v-if="fields.registrationStatus.value === 'Registration Complete'"
      :kids="kids"
      :fields="fields"
    />

    <div v-if="fields.registrationStatus.value != 'Registration Complete'">
      <div
        v-if="formStep == 1"
        class="relative overflow-hidden text-left flex flex-col gap-6 mx-auto p-6 py-6 pb-10 mt-12 border rounded-lg bg-white"
        :class="{ 'border border-red-400 ring ring-red-100': fieldsError }"
      >
        <CardLoader v-if="loading" />

        <div>
          <h2 v-if="newRegistration">Enter your information to register.</h2>
          <p v-if="newRegistration" class="text-gray-500 font-semibold mt-2">
            We're excited to have your kids participate in Guthrie Soccer!
          </p>

          <h2 v-if="!newRegistration">
            Verify and change your information below.
          </h2>
          <p v-if="!newRegistration" class="text-gray-500 font-semibold mt-2">
            Your information has been prefilled from last season. Please update
            it as necessary.
          </p>
        </div>

        <h3 class="text-base mt-2">Contact Info</h3>

        <div class="flex flex-col md:flex-row gap-4">
          <div class="flex-grow">
            <label for="householdLastName"
              >Household Last Name<span v-if="fields.householdLastName.required"
                >*</span
              ></label
            >
            <t-input
              v-if="!reviewInformation"
              placeholder="Enter Last Name"
              :variant="{
                error: fields.householdLastName.error,
              }"
              :value="fields.householdLastName.value"
              @input="updateField($event, 'householdLastName')"
              id="householdLastName"
            />
          </div>
          <div class="flex-grow">
            <label for="parent1Name"
              >Parent 1 Name<span v-if="fields.parent1Name.required"
                >*</span
              ></label
            >
            <t-input
              v-if="!reviewInformation"
              placeholder="Enter First Name"
              :variant="{
                error: fields.parent1Name.error,
              }"
              :value="fields.parent1Name.value"
              @input="updateField($event, 'parent1Name')"
              id="parent1Name"
            />
          </div>
          <div class="flex-grow">
            <label for="parent2Name"
              >Parent 2 Name<span v-if="fields.parent2Name.required"
                >*</span
              ></label
            >
            <t-input
              v-if="!reviewInformation"
              placeholder="Enter First Name"
              :variant="{
                error: fields.parent2Name.error,
              }"
              :value="fields.parent2Name.value"
              @input="updateField($event, 'parent2Name')"
              id="parent2Name"
            />
          </div>
        </div>

        <div class="flex flex-col md:flex-row gap-4">
          <div>
            <label for="phoneNumber"
              >Phone Number<span v-if="fields.phoneNumber.required"
                >*</span
              ></label
            >
            <t-input
              placeholder="(xxx) xxx-xxxx"
              :variant="{
                error: fields.phoneNumber.error,
              }"
              :value="fields.phoneNumber.value"
              @input="updateField($event, 'phoneNumber')"
              id="phoneNumber"
            />
          </div>

          <div class="flex-grow">
            <label for="email"
              >Email<span v-if="fields.email.required">*</span></label
            >
            <t-input
              placeholder="myemail@email.com"
              :variant="{
                error: fields.email.error,
              }"
              :value="fields.email.value"
              @input="updateField($event, 'email')"
              id="email"
            />
          </div>
        </div>

        <h3 class="text-base mt-6">Address</h3>

        <div class="flex flex-col md:flex-row gap-4">
          <div class="flex-grow">
            <label for="address1"
              >Address Line 1<span v-if="fields.address1.required"
                >*</span
              ></label
            >
            <t-input
              placeholder="Street Address"
              :variant="{
                error: fields.address1.error,
              }"
              :value="fields.address1.value"
              @input="updateField($event, 'address1')"
              id="address1"
            />
          </div>
          <div class="flex-grow">
            <label for="address2"
              >Address Line 2<span v-if="fields.address2.required"
                >*</span
              ></label
            >
            <t-input
              placeholder="Apt/P.O. Box"
              :variant="{
                error: fields.address2.error,
              }"
              :value="fields.address2.value"
              @input="updateField($event, 'address2')"
              id="address2"
            />
          </div>
        </div>

        <div class="flex flex-col md:flex-row gap-4">
          <div class="flex-grow">
            <label for="addressCity"
              >City<span v-if="fields.addressCity.required">*</span></label
            >
            <t-input
              v-if="!reviewInformation"
              placeholder=""
              :variant="{
                error: fields.addressCity.error,
              }"
              :value="fields.addressCity.value"
              @input="updateField($event, 'addressCity')"
              id="addressCity"
            />
          </div>
          <div>
            <label for="addressState"
              >State<span v-if="fields.addressState.required">*</span></label
            >
            <t-select
              v-if="!reviewInformation"
              placeholder="Select a State"
              :variant="{
                error: fields.addressState.error,
              }"
              :options="statesArray"
              :value="fields.addressState.value"
              @input="updateField($event, 'addressState')"
              id="addressState"
            />
          </div>
          <div>
            <label for="addressZip"
              >Zip<span v-if="fields.addressZip.required">*</span></label
            >
            <t-input
              v-if="!reviewInformation"
              placeholder=""
              :variant="{
                error: fields.addressZip.error,
              }"
              type="number"
              :value="fields.addressZip.value"
              @input="updateField($event, 'addressZip')"
              id="addressZip"
            />
          </div>
        </div>

        <h3 class="text-base mt-6">Medical Info</h3>

        <div class="flex flex-col md:flex-row gap-4">
          <div class="flex-grow">
            <label for="medicalCarrier"
              >Medical Carrier<span v-if="fields.medicalCarrier.required"
                >*</span
              ></label
            >
            <t-input
              placeholder="Enter Medical Carrier Name"
              :variant="{
                error: fields.medicalCarrier.error,
              }"
              :value="fields.medicalCarrier.value"
              @input="updateField($event, 'medicalCarrier')"
              id="medicalCarrier"
            />
          </div>
          <div class="flex-grow">
            <label for="medicalMemberID"
              >Member ID<span v-if="fields.medicalMemberID.required"
                >*</span
              ></label
            >
            <t-input
              placeholder="Enter Medical Member ID"
              :variant="{
                error: fields.medicalMemberID.error,
              }"
              :value="fields.medicalMemberID.value"
              @input="updateField($event, 'medicalMemberID')"
              id="medicalMemberID"
            />
          </div>
          <div class="flex-grow">
            <label for="medicalGroupID"
              >Group ID<span v-if="fields.medicalGroupID.required"
                >*</span
              ></label
            >
            <t-input
              placeholder="Enter Medical Group ID"
              :variant="{
                error: fields.medicalGroupID.error,
              }"
              :value="fields.medicalGroupID.value"
              @input="updateField($event, 'medicalGroupID')"
              id="medicalGroupID"
            />
          </div>
        </div>

        <div class="flex flex-col md:flex-row gap-4">
          <p class="italic text-sm text-gray-700">
            I, the parent or legal guardian of child(ren) registered for Guthrie
            Soccer, do hereby agree and consent to his/her participation in the
            Guthrie Soccer program as indicated, and do hereby release league
            officials, coaches, sponsoring agencies, Bob Jones University, and
            Guthrie Soccer from any and all claims for damages for personal
            injury, disease, or death. My consent and the waiver are given upon
            express condition that customary insurance for accident and
            hospitalization covering soccer activities will be maintained by the
            parent or legal guardian; each player must be covered by such
            insurance.
          </p>
        </div>
      </div>

      <div
        v-if="formStep == 1 && ageGroupsMaxed.length"
        class="overflow-hidden relative text-left flex flex-col gap-6 mx-auto p-6 py-6 mt-12 border rounded-lg bg-white"
      >
        <div class="text-left flex flex-col gap-2 rounded">
          <div class="flex gap-6 items-start">
            <div class="flex-grow">
              <h2>Filled Age Groups</h2>
              <p class="text-gray-500 font-semibold mt-2">
                If you would like to register your child in one of these
                maxed-out age groups, please email us at
                <a href="mailto:guthrie.soccer@gmail.com"
                  >guthrie.soccer@gmail.com</a
                >
              </p>

              <ul class="flex flex-col gap-6 mt-8">
                <li
                  v-for="(ageGroup, index) in ageGroupsMaxed"
                  :key="index"
                  class="block bg-red-100 border border-red-300 shadow rounded p-4 font-semibold"
                >
                  {{ ageGroup.gender === "Male" ? "Boys" : "Girls" }}
                  <span class="text-sm ml-2"
                    >{{ ageGroup.minAge }}-{{ ageGroup.maxAge }} Years Old</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="formStep == 1"
        class="overflow-hidden relative text-left flex flex-col gap-6 mx-auto p-6 py-6 mt-12 border rounded-lg bg-white"
        :class="{ 'border border-red-400 ring ring-red-100': kidsError }"
      >
        <CardLoader v-if="loading" />

        <div class="text-left flex flex-col gap-2 rounded">
          <div class="flex gap-6 items-start">
            <div class="flex-grow">
              <h2>Kids</h2>

              <p class="text-gray-500 font-semibold mt-2">
                Enter information for each child you would like to register for
                this season.
              </p>
              <p class="text-xs mt-1 text-gray-500 font-semibold">
                First child is ${{ $store.state.preferences.base_kid_price }},
                each child after that has a ${{
                  $store.state.preferences.discount_per_kid
                }}
                additive discount (max discount begins at child
                {{ $store.state.preferences.max_kid_discount }} onward)
              </p>
            </div>
            <div class="text-right">
              <label>Registration Cost</label>
              <h2>${{ totalCost() }}</h2>
            </div>
          </div>

          <ul class="flex flex-col gap-6 mt-8">
            <li
              v-for="(kid, index) in kids"
              :key="index"
              class="block bg-gray-100 border border-gray-300 shadow rounded p-4 gap-4 flex flex-col"
            >
              <div class="flex items-center">
                <h3 class="mr-5">{{ kid.name.value || "New Child" }}</h3>
                <a
                  class="text-blue-500 text-sm hover:underline cursor-pointer"
                  @click="removeKid(index)"
                  v-if="kids.length > 1"
                  tabindex="0"
                  >Remove Child</a
                >
                <div class="flex-grow"></div>
                <h4 class="ml-2 text-gray-400">${{ getCost(index) }}</h4>
              </div>

              <div class="flex flex-col md:flex-row gap-4">
                <div class="flex-grow">
                  <label :for="`kidName-${index}`"
                    >First Name<span v-if="kid.name.required">*</span></label
                  >
                  <t-input
                    placeholder="Enter First Name"
                    :variant="{
                      error: kid.name.error,
                    }"
                    :value="kid.name.value"
                    @input="updateKidField($event, 'name', index)"
                    :id="`kidName-${index}`"
                  />
                </div>
                <div class="flex-grow">
                  <label :for="`kidBirthDate-${index}`"
                    >Birth Date<span v-if="kid.birthDate.required">*</span>
                    <font-awesome-icon
                      :icon="['far', 'info-circle']"
                      class="ml-1 text-gray-500"
                      content="Guthrie Soccer is for children who are ages 5-11."
                      v-tippy
                    />
                  </label>
                  <t-input
                    placeholder="mm/dd/yy"
                    :variant="{
                      error: kid.birthDate.error,
                    }"
                    :value="kid.birthDate.value"
                    @input="updateKidField($event, 'birthDate', index)"
                    :id="`kidBirthDate-${index}`"
                  />
                </div>
                <div class="flex-grow">
                  <label :for="`kidGrade-${index}`"
                    >Grade<span v-if="kid.grade.required">*</span></label
                  >
                  <t-input
                    placeholder="Enter Grade"
                    :variant="{
                      error: kid.grade.error,
                    }"
                    :value="kid.grade.value"
                    @input="updateKidField($event, 'grade', index)"
                    :id="`kidGrade-${index}`"
                  />
                </div>
                <div class="flex-grow">
                  <label :for="`kidGender-${index}`"
                    >Gender<span v-if="kid.gender.required">*</span></label
                  >
                  <t-select
                    placeholder="Select Gender"
                    :variant="{
                      error: kid.gender.error,
                    }"
                    :options="genderOptions"
                    :value="kid.gender.value"
                    @input="updateKidField($event, 'gender', index)"
                    :id="`kidGender-${index}`"
                  />
                </div>
              </div>

              <div class="flex flex-col md:flex-row gap-4">
                <div>
                  <label :for="`kidPlayUp-${index}`">
                    Prefer Play Up
                    <font-awesome-icon
                      :icon="['far', 'info-circle']"
                      class="mr-1 text-gray-500"
                      content="You may request for your child to <strong>“play up”</strong> in the next age category. This might be more likely if your child is larger for his/her age or has played a number of seasons in addition to the Guthrie Soccer program."
                      v-tippy
                    />
                  </label>
                  <div class="pt-2">
                    <t-toggle
                      :checked="kid.preferPlayUp.value"
                      @input="updateKidField($event, 'preferPlayUp', index)"
                      :id="`kidPlayUp-${index}`"
                    />
                  </div>
                </div>
                <div class="flex-grow">
                  <label :for="`kidExperience-${index}`"
                    >Soccer Experience<span v-if="kid.soccerExperience.required"
                      >*</span
                    ></label
                  >
                  <t-select
                    placeholder="Select Experience"
                    :variant="{
                      error: kid.soccerExperience.error,
                    }"
                    :options="soccerExperienceOptions"
                    :value="kid.soccerExperience.value"
                    @input="updateKidField($event, 'soccerExperience', index)"
                    :id="`kidExperience-${index}`"
                  />
                </div>
                <div class="flex-grow">
                  <label :for="`kidSeasons-${index}`"
                    ># of Guthrie Soccer Seasons
                    <span v-if="kid.guthrieSeasons.required">*</span></label
                  >
                  <t-select
                    placeholder="Select Seasons"
                    :variant="{
                      error: kid.guthrieSeasons.error,
                    }"
                    :options="guthrieSeasonsOptions"
                    :value="kid.guthrieSeasons.value"
                    @input="updateKidField($event, 'guthrieSeasons', index)"
                    :id="`kidSeasons-${index}`"
                  />
                </div>
              </div>

              <div class="flex flex-col md:flex-row gap-4">
                <div>
                  <label :for="`kidSchool-${index}`"
                    >School<span v-if="kid.school.required">*</span></label
                  >
                  <t-select
                    placeholder="Select School"
                    :variant="{
                      error: kid.school.error,
                    }"
                    :options="schoolOptions"
                    :value="kid.school.value"
                    @input="updateKidField($event, 'school', index)"
                    :id="`kidSchool-${index}`"
                  />
                </div>
                <div
                  v-if="
                    kid.school && kid.school.value
                      ? kid.school.value.toLowerCase() === 'other'
                        ? true
                        : false
                      : false
                  "
                >
                  <label :for="`kidOtherSchool-${index}`"
                    >Other School<span v-if="kid.schoolOther.required"
                      >*</span
                    ></label
                  >
                  <t-input
                    placeholder="Enter School Name"
                    :variant="{
                      error: kid.schoolOther.error,
                    }"
                    :value="kid.schoolOther.value"
                    @input="updateKidField($event, 'schoolOther', index)"
                    :id="`kidOtherSchool-${index}`"
                  />
                </div>
                <div>
                  <label :for="`kidShirtSize-${index}`">
                    Shirt Size<span v-if="kid.shirtSize.required">*</span>
                    <font-awesome-icon
                      :icon="['far', 'info-circle']"
                      class="ml-1 text-gray-500"
                      content="We order shirts a month before the season starts, but we will do our best to get the size that you order for your child. <br/><br/><strong>In previous years 50% of shirts were YS, 30% of shirts were YM, 15% of shirts were YL.</strong>"
                      v-tippy
                    />
                  </label>
                  <t-select
                    placeholder="Select Shirt Size"
                    :variant="{
                      error: kid.shirtSize.error,
                    }"
                    :options="shirtSizeOptions"
                    :value="kid.shirtSize.value"
                    @input="updateKidField($event, 'shirtSize', index)"
                    :id="`kidShirtSize-${index}`"
                  />
                </div>
                <div class="flex-grow">
                  <label :for="`kidAvoidDay-${index}`">
                    Avoid Day
                    <span v-if="kid.avoidDay.required">*</span>
                    <font-awesome-icon
                      :icon="['far', 'info-circle']"
                      class="ml-1 text-gray-500"
                      content="If your child has an unavoidable conflict (e.g., music lesson, Scouts) that occurs on M, T, or Th each week, let us know. We will do our best to put your child on a team that does not have games on that night."
                      v-tippy
                    />
                  </label>
                  <t-select
                    placeholder="Select"
                    :variant="{
                      error: kid.avoidDay.error,
                    }"
                    :options="avoidDayOptions"
                    :value="kid.avoidDay.value"
                    @input="updateKidField($event, 'avoidDay', index)"
                    :id="`kidAvoidDay-${index}`"
                  />
                </div>
              </div>

              <div class="flex flex-col md:flex-row gap-4">
                <div class="flex-grow">
                  <label :for="`kidAllergies-${index}`"
                    >Allergies/Medical Notes<span
                      v-if="kid.allergiesMedicalNotes.required"
                      >*</span
                    ></label
                  >
                  <t-input
                    placeholder="Enter Notes"
                    :variant="{
                      error: kid.allergiesMedicalNotes.error,
                    }"
                    :value="kid.allergiesMedicalNotes.value"
                    @input="
                      updateKidField($event, 'allergiesMedicalNotes', index)
                    "
                    :id="`kidAllergies-${index}`"
                  />
                </div>
                <div class="flex-grow">
                  <label :for="`kidPreferredTeammates-${index}`">
                    Preferred Teammates (max 2)
                    <span v-if="kid.preferredTeammates.required">*</span>
                    <font-awesome-icon
                      :icon="['far', 'info-circle']"
                      class="ml-1 text-gray-500"
                      content="<strong>Siblings in the same age category are AUTOMATICALLY placed on same team.</strong> We can place cousins together, but you have to let us know (they will not automatically be placed on the same team). If you don’t register siblings in the same age category, you may enter one teammate request per child. The teammate you request should also request your child, and the requested teammate must be same age group as your child. We will do our best to grant your child’s first teammate request. If that teammate isn’t available, we’ll try to grant their second request, etc."
                      v-tippy
                    />
                  </label>
                  <t-input
                    placeholder="Enter Full Names"
                    :variant="{
                      error: kid.preferredTeammates.error,
                    }"
                    :value="kid.preferredTeammates.value"
                    @input="updateKidField($event, 'preferredTeammates', index)"
                    :id="`kidPreferredTeammates-${index}`"
                  />
                </div>
              </div>
            </li>
            <li>
              <button
                @click="addKid(null)"
                class="flex items-center bg-gray-100 w-full rounded p-4 hover:bg-gray-200 cursor-pointer focus:border-blue-400"
              >
                <h4>
                  <font-awesome-icon
                    :icon="['far', 'plus-circle']"
                    class="mr-1 text-gray-500"
                  />
                  Add a Child
                </h4>
              </button>
            </li>
          </ul>

          <div class="flex gap-6 items-center mt-4">
            <div class="flex-grow"></div>
            <div class="text-right">
              <label>Registration Cost</label>
              <h2>${{ totalCost() }}</h2>
            </div>
          </div>

          <div class="flex gap-2 items-end mt-4">
            <div class="flex-grow"></div>
            <div v-if="!promoCodeObj">
              <label for="promoCode">Have a Promo Code?</label>
              <t-input
                placeholder="Enter Promo Code"
                :variant="{
                  error: promoError,
                }"
                v-model="promoCode"
                @keyup.enter.native="applyPromoCode"
                id="promoCode"
              />
            </div>
            <t-button
              v-if="!promoCodeObj"
              variant="secondary"
              @click="applyPromoCode"
              >Apply</t-button
            >
            <div v-if="promoCodeObj" class="flex">
              <p>
                Applied <span class="font-semibold">{{ promoCode }}</span>
              </p>
              <t-button variant="link" @click="clearPromoCode" class="ml-2"
                >Clear</t-button
              >
            </div>
          </div>
        </div>

        <div class="flex justify-end items-center gap-4">
          <span v-if="formError" class="text-red-500 font-semibold"
            >Review Form for Errors</span
          >
          <t-button @click="confirmAndReviewInformation"
            >Confirm &amp; Review Information</t-button
          >
        </div>
      </div>

      <div
        v-if="formStep == 3"
        class="text-left flex flex-col gap-6 mx-auto p-4 md:p-6 py-6 mt-12 pt-2 md:pt-4 border rounded-lg bg-white"
      >
        <div class="flex gap-6 items-center mt-0">
          <div class="flex-grow">
            <h2>Please verify your information below.</h2>
          </div>
          <div class="text-right">
            <label>Registration Cost</label>
            <h2>${{ totalCost() }}</h2>
          </div>
        </div>

        <SectionContactInfoView
          :householdLastName="fields.householdLastName.value"
          :parent1Name="fields.parent1Name.value"
          :parent2Name="fields.parent2Name.value"
          :phoneNumber="fields.phoneNumber.value"
          :email="fields.email.value"
        />

        <SectionAddressView
          :address1="fields.address1.value"
          :address2="fields.address2.value"
          :city="fields.addressCity.value"
          :state="fields.addressState.value"
          :zip="fields.addressZip.value"
        />

        <SectionMedicalInfoView
          :medicalCarrier="fields.medicalCarrier.value"
          :medicalMemberID="fields.medicalMemberID.value"
          :medicalGroupID="fields.medicalGroupID.value"
        />

        <SectionKidsView :kids="kids" :promoCodeObj="promoCodeObj" />

        <div class="flex justify-between gap-4">
          <t-button @click="formStep = 1" variant="secondary">Back</t-button>
          <t-button @click="submitForm" v-if="!loading"
            >Confirm Information &amp; Pay ${{ totalCost() }}</t-button
          >
          <t-button v-if="loading" disabled="disabled"
            ><font-awesome-icon
              :icon="['far', 'spinner']"
              class="fa-spin text-white"
          /></t-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Airtable from "airtable";
const base = new Airtable({
  apiKey:
    "patoTm7XJS3z3B9kb.d6a8954f9b42ee421609512a137d8c5924bc51122c510883bae54f6d23e0c7e5",
}).base("appcJIEiDAj4bYz1T");

import { mapState } from "vuex";
import { loadStripe } from "@stripe/stripe-js";
import {
  statesArray,
  soccerExperienceOptions,
  genderOptions,
  shirtSizeOptions,
  schoolOptions,
  guthrieSeasonsOptions,
  avoidDayOptions,
} from "@/lib/Constants";

import CardLoader from "@/components/CardLoader";
import SectionAlreadyRegistered from "@/components/SectionAlreadyRegistered";
import SectionContactInfoView from "@/components/SectionContactInfoView";
import SectionAddressView from "@/components/SectionAddressView";
import SectionMedicalInfoView from "@/components/SectionMedicalInfoView";
import SectionKidsView from "@/components/SectionKidsView";
import { getCost, getKidCost } from "../lib/helpers";

const stripePromise = loadStripe(
  "pk_live_51I5bGsEFjYzrKjvLbAI0iwY7GaulzPPaW3zR5JTbB7VrpZfCUup0Zte10kH3DNiz3Aydve2lHmwKaoaxEF6KrQnP00DArui8If"
);

export default {
  name: "Register",
  data() {
    return {
      searchResults: [],
      formError: false,
      fieldsError: false,
      kidsError: false,
      reviewInformation: false,
      formStep: 1,
      loading: false,
      statesArray: statesArray,
      soccerExperienceOptions: soccerExperienceOptions,
      guthrieSeasonsOptions: guthrieSeasonsOptions,
      genderOptions: genderOptions,
      schoolOptions: schoolOptions,
      shirtSizeOptions: shirtSizeOptions,
      avoidDayOptions: avoidDayOptions,
      promoCode: "",
      promoCodeObj: false,
      promoError: false,
      ageGroups: [],
    };
  },
  computed: {
    ageGroupsMaxed() {
      return this.ageGroups.filter((item) => item.maxedOut === "True");
    },

    ...mapState(["fields", "kids", "newRegistration", "preferences"]),
  },
  methods: {
    async confirmAndReviewInformation() {
      this.fieldsError = await this.$store.dispatch("validateFields");
      this.kidsError = await this.$store.dispatch("validateKids");

      if (!this.fieldsError && !this.kidsError) {
        this.formStep = 3;
        this.formError = false;
      } else {
        if (this.fieldsError) {
          window.scroll({
            top: 0,
            behavior: "smooth",
          });
        }
        this.formError = true;
      }
    },
    async submitForm() {
      this.loading = true;
      // create or update record in airtable
      try {
        await this.$store.dispatch("syncToAirtable");
      } catch (err) {
        // TODO: show an error to users if sync to airtable is unsuccessful
        console.error(err);
        return;
      }

      if (this.totalCost() > 0) {
        // proceed to stripe
        const stripe = await stripePromise;

        let postBody = {
          total: this.totalCost(),
          email: this.fields.email.value,
          airtableID: this.$store.state.fields.airtableID,
        };

        if (this.promoCodeObj) {
          postBody.promoCodeID = this.promoCodeObj.id;
        }

        const response = await fetch("/api/checkout", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(postBody),
        });

        const session = await response.json();

        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
          sessionId: session.id,
        });

        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          console.error(result.error);
        }
      } else {
        this.$router.push({
          path: "/register/payment-complete",
          query: {
            id: this.$store.state.fields.airtableID,
            promoCodeID: this.promoCodeObj.id,
            amount: this.totalCost(),
          },
        });
      }
    },
    kidClick() {},
    addKid() {
      this.$store.dispatch("addKid", {});
    },
    updateField(newValue, field) {
      this.$store.dispatch("updateHouseholdField", { field, newValue });
    },
    updateKidField(newValue, field, id) {
      this.$store.dispatch("updateKidField", { id, field, newValue });
    },
    removeKid(id) {
      this.$store.commit("REMOVE_KID", id);
    },
    totalCost() {
      // let amount = 0;
      let chargedKids = this.kids.length;

      // apply "kids free" promo codes first
      // TODO: move this calculation to the server (or at least validate it there)
      if (this.promoCodeObj) {
        if (this.promoCodeObj.type === "1 Kid Free") {
          chargedKids -= 1;
        } else if (this.promoCodeObj.type === "2 Kids Free") {
          chargedKids -= 2;
        } else if (this.promoCodeObj.type === "3 Kids Free") {
          chargedKids -= 3;
        }

        if (chargedKids < 0) {
          chargedKids = 0;
        }
      }
      console.log(
        chargedKids,
        this.$store.state.preferences,
      );

      return getCost(
        chargedKids,
        this.$store.state.preferences.base_kid_price,
        this.$store.state.preferences.discount_per_kid,
        this.$store.state.preferences.max_kid_discount
      );
    },
    getCost(index) {
      let localIndex = index;

      // apply "kids free" promo codes first
      // TODO: move this calculation to the server (or at least validate it there)
      if (this.promoCodeObj) {
        if (this.promoCodeObj.type === "1 Kid Free") {
          localIndex -= 1;
        } else if (this.promoCodeObj.type === "2 Kids Free") {
          localIndex -= 2;
        } else if (this.promoCodeObj.type === "3 Kids Free") {
          localIndex -= 3;
        }
      }

      if (localIndex < 0) {
        return 0;
      }

      // return 50;

      // TODO: make this automatic based on date (and calculate on server too)
      return getKidCost(
        localIndex,
        this.$store.state.preferences.base_kid_price,
        this.$store.state.preferences.discount_per_kid,
        this.$store.state.preferences.max_kid_discount
      );
    },
    clearPromoCode() {
      this.promoCodeObj = false;
      this.promoError = false;
      this.promoCode = "";
    },
    async applyPromoCode() {
      const promoResult = await this.$store.dispatch(
        "validatePromoCode",
        this.promoCode
      );

      if (promoResult !== false) {
        this.promoCodeObj = promoResult;
        this.promoError = false;
      } else {
        this.promoError = true;
        this.promoCode = "";
      }
    },
  },
  async mounted() {
    let self = this;

    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("canceled")) {
      // load airtable id data
      this.loading = true;
      this.$store.commit("SET_REGISTRATION_STARTED", true);
      await this.$store.dispatch("loadHousehold", query.get("id"));
      this.loading = false;
    }

    // if user hasn't started the registration yet, redirect to lookup form
    if (!this.$store.state.registrationStarted) {
      this.$router.push("/register");
    } else {
      if (this.kids.length == 0) {
        this.$store.dispatch("addKid");
      }
    }

    // get age group counts
    base("Age Groups")
      .select({
        view: "All",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          // This function (`page`) will get called for each page of records.
          records.forEach(function (record) {
            // add to search results results
            self.ageGroups.push({
              airtableID: record.getId(),
              name: record.get("Name"),
              minAge: record.get("Min Age"),
              maxAge: record.get("Max Age"),
              gender: record.get("Gender"),
              maxKids: record.get("Max Kids"),
              registered: record.get("Registered"),
              maxedOut: record.get("Maxed Out"),
            });
          });

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();
        },
        async function done(err) {
          if (err) {
            console.error(err);
          }
        }
      );
  },
  components: {
    CardLoader,
    SectionAlreadyRegistered,
    SectionContactInfoView,
    SectionAddressView,
    SectionMedicalInfoView,
    SectionKidsView,
  },
};
</script>

<style lang="stylus" scoped></style>
