<template>
  <div>
    <div class="text-left flex flex-col gap-2 mt-2 rounded">
      <h3 class="text-base mt-4">Kids</h3>

      <ul class="flex flex-col gap-4 mt-4">
        <li
          v-for="(kid, index) in kids"
          :key="index"
          class="block bg-gray-100 rounded p-4 gap-4 flex flex-col"
        >
          <div class="flex items-center">
            <h3 class="flex-grow">{{ kid.name.value }}</h3>
            <h4 class="text-gray-400">${{ getCost(index) }}</h4>
          </div>

          <div class="md:pl-4 md:border-l border-gray-300 gap-4 flex flex-col">
            <div class="flex flex-col md:flex-row gap-4 md:gap-8">
              <div>
                <label>Birth Date</label>
                <p>{{ kid.birthDate.value }}</p>
              </div>
              <div>
                <label>Grade</label>
                <p>{{ kid.grade.value }}</p>
              </div>
              <div>
                <label>Gender</label>
                <p>{{ kid.gender.value }}</p>
              </div>
              <div>
                <label>Prefer Play Up</label>
                <p>{{ kid.preferPlayUp.value === true ? "Yes" : "No" }}</p>
              </div>
              <div>
                <label>Soccer Experience</label>
                <p>{{ kid.soccerExperience.value }}</p>
              </div>
              <div>
                <label># of Guthrie Soccer Seasons </label>
                <p>{{ kid.guthrieSeasons.value }}</p>
              </div>
            </div>

            <div class="flex flex-col md:flex-row gap-4 md:gap-8">
              <div>
                <label>School</label>
                <p>{{ kid.school.value }}</p>
              </div>
              <div v-if="kid.school.value === 'Other'">
                <label>Other School</label>
                <p>{{ kid.schoolOther.value }}</p>
              </div>
              <div>
                <label>Shirt Size</label>
                <p>{{ kid.shirtSize.value }}</p>
              </div>
              <div :title="kid.allergiesMedicalNotes.value">
                <label>Allergies/Medical Notes</label>
                <p class="truncate" style="max-width: 250px">
                  {{ kid.allergiesMedicalNotes.value || "--" }}
                </p>
              </div>
              <div :title="kid.preferredTeammates.value">
                <label>Preferred Teammates (max 2)</label>
                <p class="truncate" style="max-width: 250px">
                  {{ kid.preferredTeammates.value || "--" }}
                </p>
              </div>
              <div>
                <label>Avoid Day</label>
                <p>
                  {{ kid.avoidDay.value || "--" }}
                </p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getKidCost } from "../lib/helpers";

export default {
  name: "SectionKidsView",
  props: ["kids", "promoCodeObj"],
  methods: {
    getCost(index) {
      let localIndex = index;

      // apply "kids free" promo codes first
      // TODO: move this calculation to the server (or at least validate it there)
      if (this.promoCodeObj) {
        if (this.promoCodeObj.type === "1 Kid Free") {
          localIndex -= 1;
        } else if (this.promoCodeObj.type === "2 Kids Free") {
          localIndex -= 2;
        } else if (this.promoCodeObj.type === "3 Kids Free") {
          localIndex -= 3;
        }
      }

      if (localIndex < 0) {
        return 0;
      }

      return getKidCost(
        localIndex,
        this.$store.state.preferences.base_kid_price,
        this.$store.state.preferences.discount_per_kid,
        this.$store.state.preferences.max_kid_discount
      );
    },
  },
};
</script>
