<template>
  <div>
    <h3 class="text-base mt-4">Medical Info</h3>

    <div
      class="flex flex-col md:flex-row gap-4 mt-4 md:gap-8 md:pl-4 md:border-l border-gray-200"
    >
      <div>
        <label>Medical Carrier</label>
        <p>{{ medicalCarrier || "--" }}</p>
      </div>
      <div>
        <label>Member ID</label>
        <p>{{ medicalMemberID || "--" }}</p>
      </div>
      <div>
        <label>Group ID</label>
        <p>{{ medicalGroupID || "--" }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionMedicalInfoView",
  props: ["medicalCarrier", "medicalMemberID", "medicalGroupID"],
};
</script>