/**
 *
 * @param {number} discount discount amount
 * @param {number} index current kid
 * @param {number } max max discount number (ie if 4, the 4th kid onward will receive the max discount)
 * @returns
 *
 * @example
 *
 * ex. 1 - 1st kid:
 *   discount   index (min)   max kids   discount
 *   5        * min(0,        4-1)         = 0
 *
 * ex. 2 - 4rd kid (at max discount):
 *   discount   index (min)   max kids   discount
 *   5        * min(3,        4-1)         = 15
 *
 * ex. 3 - 5rd kid (over max discount):
 *   discount   index (min)   max kids   discount
 *   5        * min(4,        4-1)         = 15
 */
const getDiscountAtIndex = (discount, index, max) => {
  return discount * Math.min(index, max - 1);
};

/**
 * Get overall Cost
 * @param {number} numberOfKids total number of kids
 * @param {number} baseCost base cost for first kid
 * @param {number} discountPerKid amount to subtract extra for each kid (additive)
 * @param {number} maxKidDiscount max number of kids before discount caps
 * @returns {number} total amount
 */
export const getCost = (
  numberOfKids,
  baseCost,
  discountPerKid,
  maxKidDiscount
) => {
  let amount = 0;

  for (var i = 0; i < numberOfKids; i++) {
    amount += baseCost - getDiscountAtIndex(discountPerKid, i, maxKidDiscount);
  }

  return amount;
};

/**
 *
 * @param {number} kidNumber kid number (0 index)
 * @param {number} baseCost base cost for first kid
 * @param {number} discountPerKid amount to subtract extra for each kid (additive)
 * @param {number} maxKidDiscount max number of kids before discount caps
 * @returns {number} total amount for kid at inde
 */
export const getKidCost = (
  kidNumber,
  baseCost,
  discountPerKid,
  maxKidDiscount
) => {
  return (
    baseCost - getDiscountAtIndex(discountPerKid, kidNumber, maxKidDiscount)
  );
};
