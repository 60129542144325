const statesArray = [
    "AL",
    "AK",
    "AS",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FM",
    "FL",
    "GA",
    "GU",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MH",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "MP",
    "OH",
    "OK",
    "OR",
    "PW",
    "PA",
    "PR",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VI",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
];

const soccerExperienceOptions = [
    "New (<1 year)",
    "Learning (2-4 years)",
    "Experienced (5+ years)",
];

const guthrieSeasonsOptions = [
    "None",
    "1-2",
    "3-4",
    "5+"
];

const genderOptions = [
    "Male",
    "Female"
]

const shirtSizeOptions = [
    "YS",
    "YM",
    "YL",
    "AS",
    "AM",
];

const schoolOptions = [
    "Bob Jones Academy",
    "Hampton Park Christian School",
    "Homeschool/Co-op",
    "AJ Wittenberg",
    "Armstrong Elem",
    "Bells Crossing",
    "Berea Elem",
    "Berea Middle",
    "Blythe Academy",
    "Brushy Creek Elem",
    "Bryson Elem",
    "Buena Vista Elem",
    "Calvary Christian",
    "Chandler",
    "Charles Towne/Sterling",
    "Christ Church",
    "Dacusville Elem",
    "Dunbar Devl Center",
    "Duncan Elem",
    "East North Street Elem",
    "Edwards Road Bapt",
    "Gateway Elem",
    "Greenville Classical",
    "Hidden Treasure",
    "High Point Acad",
    "Kings Pointe Acad",
    "Lake Forest Elem",
    "Mauldin Christian",
    "Mitchell Road Christian",
    "Mountain View Elem",
    "Oakview Elem",
    "Paris Elem",
    "Pelham Road Bapt",
    "Pelham Road Elem",
    "Rudolph Gordon Elem",
    "Sara Collins Elem",
    "SC Virtual Charter",
    "Skyland Elem",
    "Stone Academy",
    "Taylors Elem",
    "Taylors First Bapt",
    "Thomas Kern Elem",
    "Veritas Prep",
    "Woodland Elem",
    "Other",
];

const avoidDayOptions = [
    "Monday",
    "Tuesday",
    "Thursday"
]

export {
    statesArray,
    soccerExperienceOptions,
    guthrieSeasonsOptions,
    genderOptions,
    shirtSizeOptions,
    schoolOptions,
    avoidDayOptions
}