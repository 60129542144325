<template>
    <div>
        <h3 class="text-base">Contact Info</h3>

        <div
          class="flex flex-col md:flex-row gap-4 mt-4 md:gap-8 md:pl-4 md:border-l border-gray-200"
        >
          <div>
            <label>Household Last Name</label>
            <p>{{ householdLastName }}</p>
          </div>
          <div>
            <label>Parent 1 Name</label>
            <p>{{ parent1Name || "--" }}</p>
          </div>
          <div>
            <label>Parent 2 Name</label>
            <p>{{ parent2Name || "--" }}</p>
          </div>
          <div>
            <label>Phone Number</label>
            <p>{{ phoneNumber }}</p>
          </div>

          <div class="flex-grow">
            <label>Email</label>
            <p>{{ email }}</p>
          </div>
        </div>  
    </div>
</template>

<script>
export default {
    name: "SectionContactInfoView",
    props: ['householdLastName', 'parent1Name', 'parent2Name', 'phoneNumber', 'email']
}
</script>