<template>
    <div class="flex pt-24 z-10 justify-center absolute top-0 left-0 w-full h-full bg-gray-100 bg-opacity-75">
        <font-awesome-icon :icon="['far', 'spinner']" class="fa-spin text-4xl text-gray-500" />
    </div>
</template>

<script>
export default {
    name: 'CardLoader'
}
</script>