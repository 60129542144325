<template>
  <div class="flex justify-center flex-col">
    <div
      class="flex-grow bg-white shadow py-12 md:px-24 mt-12 rounded"
    >
      <font-awesome-icon
        :icon="['far', 'badge-check']"
        class="text-green-500 text-5xl"
      />
      <h2 class="mt-4">You've already registered for this season.</h2>
      

      <!-- <h3 class="text-base mt-4">{{ kids.length }} Kid{{ kids.length > 1 ? 's' : '' }} Registered</h3>
      <span v-for="(kid, index) in kids" :key="index"
        >{{ kid.name.value }}
      </span> -->
      <p class="mt-6">
        If you have questions, feel free to
        <a href="mailto:kmartin@bju.edu?subject=Questions about Registration" class="text-blue-500 hover:underline"
          >email us.</a
        >
      </p>
    </div>

      <div
        class="text-left flex flex-col gap-6 mx-auto p-4 md:p-6 py-6 mt-12 pt-2 md:pt-4 border rounded-lg bg-white"
      >
        <div class="flex gap-6 items-center mt-0">
          <div class="flex-grow">
            <h2>Family Information</h2>
          </div>
        </div>

        <SectionContactInfoView 
          :householdLastName="fields.householdLastName.value"
          :parent1Name="fields.parent1Name.value"
          :parent2Name="fields.parent2Name.value"
          :phoneNumber="fields.phoneNumber.value"
          :email="fields.email.value"
        />

        <SectionAddressView
          :address1="fields.address1.value"
          :address2="fields.address2.value"
          :city="fields.addressCity.value"
          :state="fields.addressState.value"
          :zip="fields.addressZip.value"
        />

        <SectionMedicalInfoView
          :medicalCarrier="fields.medicalCarrier.value"
          :medicalMemberID="fields.medicalMemberID.value"
          :medicalGroupID="fields.medicalGroupID.value"
        />

        <SectionKidsView
          :kids="kids"
        />
      </div>
  </div>
</template>

<script>

import SectionContactInfoView from "@/components/SectionContactInfoView";
import SectionAddressView from "@/components/SectionAddressView";
import SectionMedicalInfoView from "@/components/SectionMedicalInfoView";
import SectionKidsView from "@/components/SectionKidsView";


export default {
  name: "SectionAlreadyRegistered",
  props: ["fields", "kids", "paid"],
  components: {
    SectionContactInfoView,
    SectionAddressView,
    SectionMedicalInfoView,
    SectionKidsView
  }
};
</script>